<template>
  <div class="box" :class="{ 'intro-y': hasAnimation }">
    <div
      class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
    >
      <h2 class="capitalize font-medium text-base mr-auto">
        {{ $t("app.log_types") }}
      </h2>
    </div>

    <div class="p-5 border-b border-gray-200 dark:border-dark-5">
      <div class="chat" :class="{ 'intro-y': hasAnimation }">
        <div
          class="chat__tabs nav grid grid-cols-1 nav-tabs justify-center"
          role="tablist"
        >
          <VTab
            :selected-tab="currentTab"
            :label="$t('app.logins', 2)"
            name="Login"
            icon="fal fa-sign-in-alt mx-4 w-5"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
            @click="onClickUpdateTab"
          />

          <VTab
            :selected-tab="currentTab"
            :label="$t('app.users', 2)"
            name="User"
            icon="fal fa-users-cog mx-4 w-5"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
            @click="onClickUpdateTab"
          />

          <VTab
            :selected-tab="currentTab"
            :label="$t('app.roles', 2)"
            name="Role"
            icon="fal fa-clipboard-user mx-4 w-5"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
            @click="onClickUpdateTab"
          />

          <VTab
            :selected-tab="currentTab"
            :label="$t('app.departments', 2)"
            name="Department"
            icon="fal fa-users mx-4 w-5"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
            @click="onClickUpdateTab"
          />

          <VTab
            :selected-tab="currentTab"
            :label="$t('app.sites', 2)"
            name="Site"
            icon="fal fa-city mx-4 w-5"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
            @click="onClickUpdateTab"
          />
        </div>
      </div>
    </div>

    <div class="p-5 border-b border-gray-200 dark:border-dark-5">
      <div class="chat" :class="{ 'intro-y': hasAnimation }">
        <div class="chat__tabs nav grid grid-cols-1 nav-tabs justify-center">
          <VTab
            name="TrainingImports"
            icon="fal fa-download mx-4 w-5"
            :label="$t('app.training_imports')"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
          />

          <VTab
            :selected-tab="currentTab"
            :label="$t('app.integrations')"
            name="Integration"
            icon="fal fa-link mx-4 w-5"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
            @click="onClickUpdateTab"
          />
        </div>
      </div>
    </div>

    <div class="p-5">
      <div class="chat" :class="{ 'intro-y': hasAnimation }">
        <div class="chat__tabs nav grid grid-cols-1 nav-tabs justify-center">
          <VTab
            name="RegistrationProcesses"
            icon="fal fa-file-user mx-4 w-5"
            :label="$t('app.registration_processes')"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
          />

          <VTab
            name="ExternalRegistration"
            icon="fal fa-external-link-alt mx-4 w-5"
            :label="$t('app.external_registrations')"
            class="flex-1 py-2 rounded-md w-full"
            lowercase-label
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Composables
import useAnimation from "@/composables/useAnimation";
// Components
import VTab from "@/components/VTab";

export default {
  components: {
    VTab
  },
  props: {
    currentTab: {
      type: String,
      default: "Login"
    }
  },
  emits: ["update:tab"],
  setup(props, { emit }) {
    // Composables
    const { hasAnimation } = useAnimation();

    // Methods
    const onClickUpdateTab = tab => {
      emit("update:tab", tab);
    };

    return {
      onClickUpdateTab,
      // useAnimation
      hasAnimation
    };
  }
};
</script>
