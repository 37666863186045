<template>
  <div>
    <VTitle :title="$t('app.logs')" class="route-title" />

    <div class="grid grid-cols-3 gap-x-8 mt-10">
      <LogData
        class="col-span-2"
        :log-type="currentTab"
        :rows="rows"
        :options="options"
        :pagination="pagination"
        :is-loading="isLoading"
        :current-option="currentOption"
        @download="onDownload"
        @update:option="onUpdateOption"
        @update:current_page="onUpdateCurrentPage"
      />

      <div>
        <LogTypes :current-tab="currentTab" @update:tab="onUpdateTab" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch, inject } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useReadMultiple from "@/composables/useReadMultiple";
import useTabs from "@/composables/useTabs";
import usePaginate from "@/composables/usePaginate";
import useDownload from "@/composables/useDownload";
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import LogTypes from "@/views/administration/logs/LogTypes";
import LogData from "@/views/administration/logs/LogData";

export default {
  components: {
    VTitle,
    LogData,
    LogTypes
  },
  props: {
    logType: {
      type: String,
      default: "Login"
    },
    resourceId: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const moment = inject("moment");

    const { download } = useDownload();
    const { currentTab, updateTab } = useTabs(props.logType);
    const { request } = useRequest();
    const { pagination, data, isLoading, read } = useReadMultiple();

    // CONSTANTS
    const documentTitle = `${t("app.logs")} - ${t("app.administration")}`;

    // DATA
    const options = ref([]);
    const currentOption = ref("");

    // COMPUTED
    const month = computed(() => {
      if (hasOption.value) {
        return moment(new Date()).month() + 1;
      }

      return options.value[currentOption.value].month;
    });

    const year = computed(() => {
      if (hasOption.value) {
        return moment(new Date()).year();
      }

      return options.value[currentOption.value].year;
    });

    const hasOption = computed(() => {
      return (
        options.value.length === 0 ||
        (!currentOption.value && currentOption.value !== 0)
      );
    });

    // METHODS
    const onDownload = async () => {
      if (this.resourceId) {
        downloadResourceLogs();
      } else {
        downloadLogs();
      }
    };

    const downloadResourceLogs = () => {
      download("administration.logs.resource", {
        log_type: currentTab.value,
        resource_id: props.resourceId
      });
    };

    const downloadLogs = () => {
      download("administration.logs.index", {
        log_type: currentTab.value,
        year: year.value,
        month: month.value
      });
    };

    const getData = async () => {
      await read({
        endpoint: "administration.logs.index",
        pathParams: {
          log_type: currentTab.value,
          year: year.value,
          month: month.value
        }
      });
    };

    const getResourceData = async () => {
      await read({
        endpoint: "administration.logs.resource",
        pathParams: {
          log_type: currentTab.value,
          resource_id: props.resourceId
        }
      });
    };

    const getOptions = async () => {
      const response = await request({
        endpoint: "administration.logs.months",
        pathParams: {
          log_type: currentTab.value
        }
      });

      options.value = response?.payload?.data || [];
      currentOption.value = 0;
    };

    const onUpdateOption = async index => {
      currentOption.value = index;
      await getData();
    };

    // WATCH
    watch(currentTab, async () => {
      pagination.value.current_page = 1;
      await getOptions();
      await getData();
    });

    // LIFECYCLE HOOKS
    onMounted(async () => {
      await getOptions();

      if (props.resourceId) {
        currentOption.value = "";
        await getResourceData();
      } else {
        await getData();
      }
    });

    const { onUpdateCurrentPage } = usePaginate(pagination, getData);

    return {
      documentTitle,
      onUpdateOption,
      currentOption,
      options,
      // useDownload
      onDownload,
      // useTabs
      onUpdateTab: updateTab,
      currentTab,
      // useReadMultiple
      getData,
      pagination,
      isLoading,
      rows: data,
      // usePaginate
      onUpdateCurrentPage
    };
  }
};
</script>
